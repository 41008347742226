import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import { AxiosResponse } from 'axios';

import { useAccountDetails, useBillComparison, useExperian } from 'api';
import { useUserState } from 'auth/authenticate';
import { ReconnectTitle, ViewCurrentBillLink } from 'component-library';
import LoadingSpinner from 'component-library/loading-spinner/LoadingSpinner';
import { routes } from 'constants/';
import { useConfig, useRestoreDetails } from 'hooks';
import { logger } from 'services';

import onViewCurrentBillClick from './onViewCurrentBillClick';
import ReconnectAccountInformation from './ReconnectAccountInfo';
import ReconnectServiceForm from './ReconnectServiceForm';
import ServiceSelection from './ServiceSelection';

const HeaderAndLinkContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

const ReconnectServiceContent: React.FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const {
    flags: { enableMPSC },
  } = useConfig();
  const { isAuthenticated } = useUserState();

  const { isAccountDetailsSuccess, accountDetailsResponse } = useAccountDetails({
    enabled: isAuthenticated,
    onError: (error: unknown): void => {
      logger.info('[Reconnect Service Page] Account Details API Call Error!', error);

      navigate(routes.error.reconnect);
    },
  });

  const { isRestoreDetailsSuccess, restoreDetails } = useRestoreDetails();

  const { isExperianSuccess, experianResponse, mutateExperian } = useExperian({
    onSuccess: (response: AxiosResponse<ExperianResponse>) => {
      logger.info('[Reconnect Service Page] Experian API Call Success!', response);

      if (restoreDetails) {
        const {
          depositAmount: depositAmountString,
          eligibilityFlag,
          totalPastDueAmount: totalPastDueAmountString,
          reconnectionFee: reconnectionFeeString,
        } = restoreDetails;
        const depositAmount = parseFloat(depositAmountString);
        const totalPastDueAmount = parseFloat(totalPastDueAmountString);
        const reconnectFee = parseFloat(reconnectionFeeString);

        if (response.data.bpemCategory === 'R887') {
          logger.info('[Reconnect Service Page] - Experian API Call Success! R887 Probate Error');

          navigate(routes.reconnect.probate);
        }

        // Interrupted Flow - now triggers AFTER experian is successful
        // TODO - Bug #52155 - restore code below once the eligibility service is fixed i.e. return correct value for reconnection fee
        if (eligibilityFlag && totalPastDueAmount + depositAmount + reconnectFee === 0) {
          // + reconnectionFee
          const webUserId = localStorage.getItem('webUserId');

          localStorage.setItem(`shouldCallApi_${webUserId}`, 'true');
          navigate(routes.reconnect.confirmation, { state: { previousPath: 'reconnect-payment-options' } });
        }
      } else {
        navigate(routes.error.reconnect);
      }
    },
    onError: (error: unknown) => {
      logger.info('[Reconnect Service Page] Experian API Call Error!', error);

      navigate(routes.error.reconnect);
    },
  });

  useEffect(() => {
    if (isRestoreDetailsSuccess && restoreDetails) {
      const experianRequest = {
        businessPartner: 123,
        premiseId: `${restoreDetails.premise}`,
        processId: 'WRE',
        webUserId: localStorage.getItem('webUserId') ?? '',
      };

      setTimeout(() => {
        mutateExperian(experianRequest);
      }, 500);
    }
  }, [isRestoreDetailsSuccess, restoreDetails]);

  const { contractAccountNo, depositAmount, eligibilityFlag, premise, totalPastDueAmount } = restoreDetails;
  const accountNumber = contractAccountNo ? contractAccountNo.toString() : '';
  const notZero =
    !!totalPastDueAmount && !!depositAmount && parseFloat(totalPastDueAmount) + parseFloat(depositAmount) !== 0;
  const enableBillComparisonQuery =
    isAuthenticated && !enableMPSC && !!premise && !!accountNumber && !!eligibilityFlag && notZero;

  const { billComparisonResponse } = useBillComparison({
    accountNumber,
    enabled: enableBillComparisonQuery,

    onError: (error: unknown) => {
      logger.info('[Reconnect Service Page] Bill Comparison API Call Error!', error);

      navigate(routes.error.reconnect);
    },
    premise,
    queryKey: ['bill-comparison', premise!, accountNumber!],
  });

  const showRenderPageContent =
    isAccountDetailsSuccess && isRestoreDetailsSuccess && restoreDetails && isExperianSuccess;

  if (showRenderPageContent) {
    return (
      <>
        <HeaderAndLinkContainer>
          <ViewCurrentBillLink onClickHandler={onViewCurrentBillClick} />
          <ReconnectTitle />
        </HeaderAndLinkContainer>
        <ReconnectAccountInformation
          accountDetailsResponse={accountDetailsResponse?.data}
          accountNumber={restoreDetails?.contractAccountNo!}
        />
        <ServiceSelection>
          <ReconnectServiceForm
            billComparisonResponse={billComparisonResponse}
            experianResponse={experianResponse?.data!}
            restoreDetails={restoreDetails}
          />
        </ServiceSelection>
      </>
    );
  }
  return <LoadingSpinner />;
};

export default ReconnectServiceContent;
